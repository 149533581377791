import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// core components
import Layout from 'components/Layout';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Parallax from 'components/Parallax/Parallax';
import SEO from 'components/SEO';

import faqStyle from 'assets/jss/material-kit-react/views/faqPage';

const FAQPage = ({ classes, data }) => {
  if (!data) return null;

  const doc = data.prismicFaqPage.data;
  const { lang } = data.prismicFaqPage;
  const alternateLanguages = data.prismicFaqPage.alternate_languages;

  const activeDocMeta = { lang, alternateLanguages };

  const headline = RichText.asText(doc.headline.raw);
  const description = RichText.render(doc.description.raw);
  const heroImage = doc.hero_image;
  const { questions } = doc;

  const [expanded, setExpanded] = useState(false);

  const { tours } = data.prismicHomepage.data;

  const menu = data.prismicMenu.data;
  if (!menu) return null;

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Layout activeDocMeta={activeDocMeta} menu={menu} tours={tours}>
      <SEO title="Frequently Asked Questions | Servitours" />
      <Parallax filter image={heroImage.url} className={classes.parallax}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>{headline}</h1>
                <br />
                <h3 className={classes.subtitle}>{description}</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            {questions.length > 1
              ? questions.map((document, index) => (
                  <ExpansionPanel
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  square={false}
                >
                  <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <h4>{RichText.asText(document.question.raw)}</h4>
                    </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                      <span>{RichText.asText(document.answer.raw)}</span>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                ))
              : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query FAQPageQuery($lang: String = "fr-fr") {
    prismicFaqPage(lang: { eq: $lang }) {
      alternate_languages {
        lang
        type
        uid
      }
      data {
        description {
          raw
        }
        headline {
          raw
        }
        hero_image {
          dimensions {
            height
            width
          }
          thumbnails {
            mobile {
              url
            }
          }
          url
        }
        questions {
          answer {
            raw
          }
          question {
            raw
          }
        }
      }
      lang
    }
    prismicHomepage(lang: { eq: $lang }) {
      data {
        tours {
          destination {
            document {
              ... on PrismicDestination {
                id
                lang
                type
                uid
                url
                data {
                  name {
                    raw
                  }
                }
              }
            }
          }
          message {
            raw
          }
        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      data {
        links {
          label {
            raw
          }
          link {
            lang
            type
            url
          }
        }
      }
    }
  }
`;

export default withStyles(faqStyle)(FAQPage);
